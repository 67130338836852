import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import Layout from '../containers/layout'
import Container from '../components/container'
import SEO from '../components/seo'
import Breadcrumb from '../components/layout/breadcrumb'
import MembershipPage from '../components/pages/membership'

const query = graphql`
  query membershipPage {
    membership: sanityManagement(_id: { regex: "/(drafts.|)management/" }) {
      _rawMembershipDetail
    }
  }
`

const Membership = props => {
  let navItemStack = [{ name: 'العضوية', slug: 'membership' }]
  const membershipDetail = useStaticQuery(query)
  const { _rawMembershipDetail } = membershipDetail.membership || {}
  return (
    <Layout>
      <SEO
        title="العضوية"
        keywords={['members', 'membership', 'الأعضاء', 'مؤسسون', 'العاملون']}
      />
      <Container>
        <Breadcrumb currentNavItems={navItemStack} />
        <MembershipPage portableText={_rawMembershipDetail} />
      </Container>
    </Layout>
  )
}
export default Membership
